<script>
export default {
  props: {
    transactionData: Object
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {

  }
};
</script>

<template>
  <div class="container" v-if="transactionData">
    <h4>게임정보</h4>
    <table class="table table-sm table-striped table-hover">
      <colgroup>
        <col style="width: 150px;"/>
        <col />
      </colgroup>
      <tbody>
      <tr>
        <th class="text-end">상위매장</th>
        <td class="text-start">{{ transactionData.parent.username }}</td>
      </tr>
      <tr>
        <th class="text-end">회원정보</th>
        <td class="text-start">{{ transactionData.user.username }} / {{ transactionData.user.siteUsername }}</td>
      </tr>

      <tr>
        <th class="text-end">게임사</th>
        <td class="text-start">{{ transactionData.vendorName }}</td>
      </tr>
      <!--
      <tr>
        <th class="text-end">딜러명</th>
        <td class="text-start">{{ transactionData.detail.dealer.name }}</td>
      </tr>
-->
      <tr>
        <th class="text-end">테이블ID</th>
        <td class="text-start">{{ transactionData.detail.tableId }}</td>
      </tr>
      <tr>
        <th class="text-end">key</th>
        <td class="text-start">{{ transactionData.key }}</td>
      </tr>


      <!-- evolution //-->
      <tr>
        <th class="text-end">배팅시간</th>
        <td class="text-start">{{ new Date(transactionData.detail.betTime).format('YYYY-MM-DD HH:mm:ss')}}</td>
      </tr>
      <!--
      <tr>
        <th class="text-end">종료시간</th>
        <td class="text-start">{{ new Date(transactionData.detail.paymentTime).format('YYYY-MM-DD HH:mm:ss')}}</td>
      </tr>
      <tr>
        <th class="text-end">상태</th>
        <td class="text-start">{{ transactionData.detail.status }}</td>
      </tr>
      -->
      </tbody>
    </table>

    <hr />
    <h4>배팅정보</h4>
    <table class="table table-sm table-striped table-hover">
      <thead>
      <tr>
        <!--<th class="text-center">RoundID</th>-->
        <th class="text-center">배팅대상</th>
        <th class="text-center">게임결과</th>
        <th class="text-center">배팅금액</th>
        <th class="text-center">당첨금액</th>
        <th class="text-center">배팅시각</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <!--
        <td class="text-center">
          <p v-for="bet in transactionData.info.bets" :key="'bet-' + bet.id">{{ bet.id }}</p>
        </td>
        -->
        <td class="text-center">
          <p v-for="bet in transactionData.info.bets" :key="'target-' + bet.id">{{ bet.target }}</p>
        </td>
        <td class="text-center">
          <p>{{ transactionData.info.result }}</p>
        </td>
        <td class="text-center">
          <p v-for="bet in transactionData.info.bets" :key="'bet2-' + bet.id">{{ bet.bet }}</p>
        </td>
        <td class="text-center">
          <p v-for="bet in transactionData.info.bets" :key="'bet3-' + bet.id" class="{'bg-primary':bet.win>0, 'bg-danger':bet.win===0}">{{ bet.win }}</p>
        </td>
        <td class="text-center">
          <p v-for="bet in transactionData.info.bets" :key="'bet4-' + bet.id">{{ new Date(bet.placedOn).format('YYYY-MM-DD HH:mm:ss') }}</p>
        </td>
      </tr>
      </tbody>
    </table>

    <template v-if="transactionData.info.detail">
      <hr />
      <h4>게임결과</h4>
      <table class="table table-sm table-striped table-hover">
        <colgroup>
          <col style="width: 50%;" />
          <col />
        </colgroup>
        <thead>
        <tr>
          <th class="text-center">
            <span v-if="transactionData.gameTypeName.toLowerCase().indexOf('baccarat') > -1">Banker(뱅커)</span>
            <span v-if="transactionData.gameTypeName.toLowerCase().indexOf('dragontiger') > -1">Dragon(드래곤)</span>
          </th>
          <th class="text-center">
            <span v-if="transactionData.gameTypeName.toLowerCase().indexOf('baccarat') > -1">Player(플레이어)</span>
            <span v-if="transactionData.gameTypeName.toLowerCase().indexOf('dragontiger') > -1">Tiger(타이거)</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="text-center">
            <template v-if="transactionData.gameTypeName.toLowerCase().indexOf('baccarat') > -1">
              <div :class="{'bg-danger':transactionData.info.result.toUpperCase() === 'BANKER'}" style="padding: 5px;">
                <img :src="transactionData.info.detail.banker.cards[2]" style="height:30px; margin-right: 5px;" v-if="transactionData.info.detail.banker.cards[2]" />
                <img :src="transactionData.info.detail.banker.cards[1]" style="width: 30px; margin-right: 5px;" v-if="transactionData.info.detail.banker.cards[1]" />
                <img :src="transactionData.info.detail.banker.cards[0]" style="width: 30px; margin-right: 5px;" v-if="transactionData.info.detail.banker.cards[0]" />
                <span style="color: black; font-size: 24px; font-weight: bold;">{{ transactionData.info.detail.banker.score }}</span>
              </div>
            </template>

            <template v-if="transactionData.gameTypeName.toLowerCase().indexOf('dragontiger') > -1">
              <div :class="{'bg-danger':transactionData.info.result.toUpperCase() === 'DRAGON'}" style="padding: 5px;">
                <img :src="transactionData.info.detail.dragon.card" style="width: 30px; margin-right: 5px;" v-if="transactionData.info.detail.dragon.card" />
                <span style="color: black; font-size: 24px; font-weight: bold;">{{ transactionData.info.detail.dragon.score }}</span>
              </div>
            </template>
          </td>
          <td class="text-center">
            <template v-if="transactionData.gameTypeName.toLowerCase().indexOf('baccarat') > -1">
              <div :class="{'bg-primary':transactionData.info.result.toUpperCase() === 'PLAYER'}" style="padding: 5px;">
                <span style="color: black; font-size: 24px; font-weight: bold; margin-right: 5px;">{{ transactionData.info.detail.player.score }}</span>
                <img :src="transactionData.info.detail.player.cards[0]" style="width: 30px; margin-right: 5px;" v-if="transactionData.info.detail.player.cards[0]" />
                <img :src="transactionData.info.detail.player.cards[1]" style="width: 30px; margin-right: 5px;" v-if="transactionData.info.detail.player.cards[1]" />
                <img :src="transactionData.info.detail.player.cards[2]" style="height: 30px;" v-if="transactionData.info.detail.player.cards[2]" />
              </div>
            </template>

            <template v-if="transactionData.gameTypeName.toLowerCase().indexOf('dragontiger') > -1">
              <div :class="{'bg-primary':transactionData.info.result.toUpperCase() === 'TIGER'}" style="padding: 5px;">
                <span style="color: black; font-size: 24px; font-weight: bold; margin-right: 5px;">{{ transactionData.info.detail.tiger.score }}</span>
                <img :src="transactionData.info.detail.tiger.card" style="width: 30px; margin-right: 5px;" v-if="transactionData.info.detail.tiger.card" />
              </div>
            </template>
          </td>
        </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>
