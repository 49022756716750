<script>
export default {
  props: {
    transactionData: Object
  },
  data() {
    return {
      displayTransactionData: null
    }
  },
  mounted() {
    this.setData()
  },
  methods: {
    setData: function() {
      if (this.transactionData.category === 'turn') {
        this.displayTransactionData = {
          transactionKey: this.transactionData.transactionKey,
          username: this.transactionData.username,
          siteUsername: this.transactionData.siteUsername,
          vendorKey: this.transactionData.vendorKey,
          vendorName: this.transactionData.vendorName,
          gameCategoryKey: this.transactionData.gameCategoryKey,
          gameKey: this.transactionData.gameKey,
          gameName: this.transactionData.gameName,
          gameTypeKey: this.transactionData.gameTypeKey,
          gameImage: this.transactionData.gameImage,
          category: this.transactionData.category,
          type: this.transactionData.type,
          key: this.transactionData.key,
          roundId: this.transactionData.siteRoundId,
          parentTransactionKey: this.transactionData.parentTransactionKey,
          isBonus: this.transactionData.isBonus,
          isJackpot: this.transactionData.isJackpot,
          isPromo: this.transactionData.isPromo,
          isFreeGame: this.transactionData.isFreeGame,
          groupKey: this.transactionData.groupKey,
          amount: this.transactionData.amount,
          beforeAmount: this.transactionData.beforeAmount,
          afterAmount: this.transactionData.afterAmount,
          data: this.transactionData.data,
          detail: this.transactionData.detail,
          seamlessState: this.transactionData.seamlessState,
          seamlessErrorMsg: this.transactionData.seamlessErrorMsg,
          createdAt: this.transactionData.createdAt,
          requestedAt: this.transactionData.requestedAt
        }
      } else {
        this.displayTransactionData = {
          user: this.transactionData.user,
          sender: this.transactionData.sender,
          amount: this.transactionData.amount,
          beforeAmount: this.transactionData.beforeAmount,
          afterAmount: this.transactionData.afterAmount,
          data: this.transactionData.data,
          createdAt: this.transactionData.createdAt
        }
      }
    }
  },
  watch: {
    transactionData: function() {
      this.setData()
    }
  }
};
  </script>

  <template>
    <div v-if="displayTransactionData">
      <pre class="rounded-lg" v-highlightjs><code class="javascript" v-html="JSON.stringify(displayTransactionData, null, ' ')"></code></pre>
    </div>
</template>
