<script>
export default {
  props: {
    transactionData: Object
  },
  data() {
    return {
      data: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList: async function() {
      const result = await this.$API.transaction.getSeamlessList(this.transactionData.transactionKey)
      if (result.code !== 0) {
        return alert(result.msg)
      }
      this.data = result.documents
    }
  }
};
</script>

<template>
  <div class="table-responsive">
    <table class="table align-middle">
      <thead class="bg-soft bg-secondary">
      <tr>
        <th>형식</th>
        <th>URL</th>
        <th>수신코드</th>
        <th>전송일시</th>
        <th>수신일시</th>
        <th>처리시간</th>
      </tr>
      <tr>
        <th colspan="3">전송데이터</th>
        <th colspan="3">수신데이터</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="record in data" :key="record.id">
        <tr>
          <td colspan="6"></td>
        </tr>
        <tr :class="{'bg-primary': record.result, 'bg-danger': !record.result}" class="bg-soft">
          <td>
            <span v-if="record.action === 'authenticate'" class="badge bg-primary">인증</span>
            <span v-if="record.action === 'bet'" class="badge bg-primary">배팅</span>
            <span v-if="record.action === 'result'" class="badge bg-primary">결과</span>
            <span v-if="record.action === 'cancel'" class="badge bg-primary">취소</span>
            <span v-if="record.action === 'balance'" class="badge bg-primary">잔액확인</span>
          </td>
          <td>{{ record.url }}</td>
          <td :class="{'bg-success': record.result, 'bg-danger': !record.result}" class="bg-soft">{{ (record.returnData.code || record.returnData.code === 0) ? record.returnData.code : '오류' }}({{ record.returnCode }})
          </td>
          <td>{{ new Date(record.requestAt).format('YYYY-MM-DD HH:mm:ss.zzz') }}</td>
          <td>{{ new Date(record.responseAt).format('YYYY-MM-DD HH:mm:ss.zzz') }}</td>
          <td>{{ parseFloat(new Date(record.responseAt).getTime() - new Date(record.requestAt).getTime()) / 1000 }}초</td>
        </tr>
        <tr style="background-color: #f9f9f9;">
          <td colspan="3" class="text-start" style="background-color: #f0f0f0;">
            <pre class="rounded-lg" v-highlightjs><code class="javascript" v-html="JSON.stringify(record.sendData, null, ' ')"></code></pre>
          </td>
          <td colspan="3" class="text-start align-top">
            <pre class="rounded-lg" v-highlightjs><code class="javascript" v-html="JSON.stringify(record.returnData, null, ' ')"></code></pre>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>
