<script>
export default {
  props: {
    transactionData: Object
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {

  }
};
</script>

<template>
  <div class="container" v-if="transactionData">
    <h4>게임정보</h4>
    <table class="table table-sm table-striped table-hover">
      <colgroup>
        <col style="width: 150px;"/>
        <col />
      </colgroup>
      <tbody>
      <tr>
        <th class="text-end">상위매장</th>
        <td class="text-start">{{ transactionData.parent.username }}</td>
      </tr>
      <tr>
        <th class="text-end">회원정보</th>
        <td class="text-start">{{ transactionData.user.username }} / {{ transactionData.user.siteUsername }}</td>
      </tr>

      <tr>
        <th class="text-end">게임사</th>
        <td class="text-start">{{ transactionData.vendorName }}</td>
      </tr>
      <tr>
        <th class="text-end">카지노명</th>
        <td class="text-start">{{ transactionData.data.detail.casino }}</td>
      </tr>
      <!--
      <tr>
        <th class="text-end">딜러명</th>
        <td class="text-start">{{ transactionData.detail.dealer.name }}</td>
      </tr>
-->
      <tr>
        <th class="text-end">테이블No</th>
        <td class="text-start">{{ transactionData.children[0].data.detail.tableNo }}</td>
      </tr>
      <tr>
        <th class="text-end">게임ID</th>
        <td class="text-start">{{ transactionData.key }}</td>
      </tr>


      <!-- evolution //-->
      <tr>
        <th class="text-end">시작시간</th>
        <td class="text-start">{{ new Date(transactionData.createdAt).format('YYYY-MM-DD HH:mm:ss')}}</td>
      </tr>
      <tr>
        <th class="text-end">종료시간</th>
        <td class="text-start">{{ new Date(transactionData.children[0].createdAt).format('YYYY-MM-DD HH:mm:ss')}}</td>
      </tr>
      <!--
      <tr>
        <th class="text-end">상태</th>
        <td class="text-start">{{ transactionData.detail.status }}</td>
      </tr>
      -->
      </tbody>
    </table>

    <hr />
    <h4>배팅정보</h4>
    <table class="table table-sm table-striped table-hover">
      <thead>
      <tr>
        <th class="text-center">배팅대상</th>
        <th class="text-center">배팅금액</th>
        <th class="text-center">게임결과</th>
        <th class="text-center">배팅시각</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="text-center">
          <p v-for="bet in transactionData.info.bets" :key="'target-' + bet.id">{{ bet.target }}</p>
        </td>
        <td class="text-center">
          <p v-for="bet in transactionData.info.bets" :key="'bet2-' + bet.id">{{ bet.bet }}</p>
        </td>
        <td class="text-center">
          <p v-for="bet in transactionData.info.bets" :key="'bet3-' + bet.id">{{ bet.result }}</p>
        </td>
        <td class="text-center">
          <p v-for="bet in transactionData.info.bets" :key="'bet4-' + bet.id">{{ new Date(bet.placedOn).format('YYYY-MM-DD HH:mm:ss') }}</p>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
