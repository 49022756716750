<script>
import detailResult from './detail-result'
import detailData from './detail-data'
import detailSeamless from './detail-seamless'
import detailResultEvolution from './detail-result-evolution'
import detailResultBG from './detail-result-bg'
import detailResultWM from './detail-result-wm'
import detailResultVIVO from './detail-result-vivo'
import detailResultAG from './detail-result-ag'
import detailResultBota from './detail-result-bota'





export default {
  props: {

  },
  components: {
    detailData,
    detailResult,
    detailSeamless,
    detailResultEvolution,
    detailResultBG,
    detailResultWM,
    detailResultVIVO,
    detailResultAG,
    detailResultBota
  },
  data() {
    return {
      transactionData: null,
      openDialog: false,
      content: ''
    }
  },
  mounted() {

  },
  methods: {
    open: function(data, page = 'detailData') {
      this.content = page
      this.transactionData = data
      this.openDialog = true
    },
    refresh: function() {
      alert('데이터 새로 고침')
    }
  }
};
</script>

<template>
  <b-modal
      title="상세정보"
      v-model="openDialog"
      size="xl"
      scrollable
      button-size="sm"
  >
    <div class="d-block" v-if="transactionData">
      <div class="card shadow shadow-sm">
        <div class="card-header pb-0">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link" :class="{active: content==='detailData'}" href="#" @click="content='detailData'">{{ $t('데이터')}}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="{active: content==='detailResult'}" href="#" @click="content='detailResult'" v-if="transactionData.category === 'turn' && transactionData.detail && transactionData.vendorKey != 'evolution_casino' && transactionData.vendorKey != 'bg_casino' && transactionData.vendorKey != 'wm_casino' && transactionData.vendorKey != 'vivo_casino' ">{{ $t('게임') + ' ' + $t('결과') }}</a>
              <a class="nav-link" :class="{active: content==='detailResultBG'}" href="#" @click="content='detailResultBG'" v-if="transactionData.category === 'turn' && transactionData.detail &&  transactionData.vendorKey == 'bg_casino' ">{{ $t('게임') + ' ' + $t('결과') }}</a>
              <a class="nav-link" :class="{active: content==='detailResultWM'}" href="#" @click="content='detailResultWM'" v-if="transactionData.category === 'turn' && transactionData.detail &&  transactionData.vendorKey == 'wm_casino' ">{{ $t('게임') + ' ' + $t('결과') }}</a>
              <a class="nav-link" :class="{active: content==='detailResultVIVO'}" href="#" @click="content='detailResultVIVO'" v-if="transactionData.category === 'turn' && transactionData.detail &&  transactionData.vendorKey == 'vivo_casino' ">{{ $t('게임') + ' ' + $t('결과') }}</a>
              <a class="nav-link" :class="{active: content==='detailResultAG'}" href="#" @click="content='detailResultAG'" v-if="transactionData.category === 'turn' && transactionData.detail &&  transactionData.vendorKey == 'ag_casino' ">{{ $t('게임') + ' ' + $t('결과') }}</a>
              <a class="nav-link" :class="{active: content==='detailResultBota'}" href="#" @click="content='detailResultBota'" v-if="transactionData.category === 'turn' && transactionData.detail &&  transactionData.vendorKey == 'bota_casino' ">{{ $t('게임') + ' ' + $t('결과') }}</a>
              <a class="nav-link" :class="{active: content==='detailResultEvolution'}" href="#" @click="content='detailResultEvolution'" v-if="transactionData.category === 'turn' && transactionData.detail &&  transactionData.vendorKey == 'evolution_casino' ">{{ $t('게임') + ' ' + $t('결과') }}</a>
            </li>
            <li class="nav-item" v-if="transactionData.seamlessState === 'error'">
              <a class="nav-link" :class="{active: content==='detailSeamless'}" href="#" @click="content='detailSeamless'" v-if="transactionData.category === 'turn'">{{ $t('전송내역') }}</a>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <component :is="content" :transactionData="transactionData"></component>
        </div>
      </div>
    </div>
  </b-modal>
</template>
